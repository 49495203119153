import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/strata`;

export const PATHS = {
    STRATA: {
        path: `${moduleRoot}`,
    },
    OVERVIEW: {
        path: `${moduleRoot}/overview`,
    },
    DOCUMENTATION: {
        path: `${moduleRoot}/pricing`,
        hardPath: HARD_PATHS.HELP.STRATA_DOCUMENTATION,
    },
    COLLECTIONS_ID: {
        path: `${moduleRoot}/collections/:libraryId`,
    },
    COLLECTIONS: {
        path: `${moduleRoot}/collections/`,
    },
    COLLECTION: {
        path: `${moduleRoot}/collection/:collectionId`,
    },
    PRICING: {
        path: `${moduleRoot}/pricing`,
    },
    LICENSING: {
        path: `${moduleRoot}/licensing/faq/strataLicenses`,
    },
    REGISTER_PROJECT: {
        path: `${moduleRoot}/projectRegistration`,
        hardPath: HARD_PATHS.GENERAL.FCRM_REGISTERED_PROJECTS,
    },
};
