// [BLOCK 1/3] Keep this as is may become useful in the near future. If this is still commented out in 2023 DELETE it
// import { TOptions } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TranslationAtKey } from './i18n';
import { unescape } from 'lodash';

import AkBlack from '../components/dictionary-components/ak-black';
import AkBold from '../components/dictionary-components/ak-bold';
import AkCyan from '../components/dictionary-components/ak-cyan';
import AkFatBlack from '../components/dictionary-components/ak-fat-black';
import AkGreyBg from '../components/dictionary-components/ak-grey-bg';
import AkGreenCheckmark from '../components/dictionary-components/ak-green-checkmark';
import AkItalic from '../components/dictionary-components/ak-italic';
import AkNoWrap from '../components/dictionary-components/ak-no-wrap';
import AkRed from '../components/dictionary-components/ak-red';
import AkYellowBg from '../components/dictionary-components/ak-yellow-bg';
import Bullet from '../components/dictionary-components/bullet';
import AkSmaller from '../components/dictionary-components/ak-smaller';

/* The JAKHUB-TRANSLATION-README has info on how to use the little masterpiece */

// type KeyWithIndex<K extends string, I extends string | number | never> = I extends never
//     ? K
//     : `${K}[${I}]`;
// type TranslationReturnObjects<P extends string> = string | (Record<P, any> & { body: string });
//
// const interpolationRegex = /\$\{([^}]*)\}/g;
//
// export const interpolate = <P extends string>(
//     translation: TranslationReturnObjects<P>,
//     interpolations: Record<P, (value: any) => React.ReactNode>
// ): string | {} => {
//     if (typeof translation === 'string') {
//         return translation;
//     } else {
//         const textParts = translation.body.split(interpolationRegex);
//
//         const interpolateSingle = <K extends string, I extends string | number | never>(
//             text: KeyWithIndex<K, I>
//         ) => {
//             const [key, index] = text.split(/[[\]]/) as [P, I];
//             const value =
//                 index && typeof translation[key].hasOwnProperty(index)
//                     ? translation[key][index]
//                     : translation[key];
//
//             return interpolations?.[key]?.(value) || text;
//         };
//
//         return textParts
//             .map((text, i) => (i % 2 ? interpolateSingle<P, any>(text as P) || text : text))
//             .map((el) => el);
//     }
// };

export interface JakhubTranslation {
    t: (
        i18nKey: string,
        components?: Record<string, React.ReactElement | React.ReactNode> // also can pass in interpolation values sometimes?
    ) => React.ReactNode;
    // [BLOCK 2/3] Keep this as is may become useful in the near future. If this is still commented out in 2023 DELETE it
    // it: <P extends string>(
    //     key: string,
    //     interpolations: Record<P, (value: string) => React.ReactNode>,
    //     options?: TOptions<any> | string
    // ) => React.ReactNode;
    tt: <K extends string>(key: K) => TranslationAtKey<K>;
    T: (
        complexString?: string,
        components?: Record<string, React.ReactElement | React.ReactNode>
    ) => React.ReactNode;
}

export const useJakhubTranslation = (): JakhubTranslation => {
    const { t } = useTranslation();
    const commonComponent = {
        div: <div />,
        a: <a />,
        b: <b />,
        figure: <figure />,
        i: <i />,
        img: <img />,
        p: <p />,
        s: <s />,
        u: <u />,
        strong: <strong />,
        em: <em />,
        code: <code />,
        h1: <h1 />,
        h2: <h2 />,
        h3: <h3 />,
        h4: <h4 />,
        h5: <h5 />,
        h6: <h6 />,
        ul: <ul />,
        ol: <ol />,
        li: <li />,
        table: <table />,
        caption: <caption />,
        tr: <tr />,
        th: <th />,
        td: <td />,
        AK_BLACK: <AkBlack />,
        AK_SMALLER: <AkSmaller />,
        AK_BOLD: <AkBold />,
        AK_BULLET: <Bullet />,
        AK_CHECKMARK: <AkGreenCheckmark />,
        AK_CYAN: <AkCyan />,
        AK_FAT_BLACK: <AkFatBlack />,
        AK_BIG_AND_BLACK: <AkFatBlack />,
        AK_GREY_BG: <AkGreyBg />,
        AK_ITALIC: <AkItalic />,
        AK_NO_WRAP: <AkNoWrap />,
        AK_RED: <AkRed />,
        AK_YELLOW_BG: <AkYellowBg />,
    };

    return {
        /**
         * Hate making a new key for every interpolated value where the value is static? Use this instead!
         * Your string can now be "This is a <link>link to something</link>", and you can tell i18n what "link" is like so:
         * t('thekey', { link: <a href="somewhere" /> })
         */
        t: (i18nKey, components) => (
            <Trans
                t={t}
                i18nKey={i18nKey}
                values={{
                    ...components,
                }}
                components={{
                    ...commonComponent,
                    ...components,
                }}
            />
        ),
        // [BLOCK 3/3] Keep this as is may become useful in the near future. If this is still commented out in 2023 DELETE it
        // it: (key, interpolations, options) => {
        //     const translation = t(key, {
        //         ...options,
        //         returnObjects: true,
        //     });
        //     return interpolate(translation, interpolations);
        // },
        tt: (key) => {
            return t(key, { returnObjects: true });
        },
        T: (complexString = '', components) => (
            <Trans
                t={t}
                i18nKey='doNotTranslate:INTERPOLATION'
                values={{
                    // Note: the replacement ' ' below is the special NBSP space character
                    // Note: the replacement '–' below is the special NDASH special character
                    // eslint-disable-next-line react/destructuring-assignment
                    INTERPOLATION: unescape(complexString)
                        .replaceAll(/&nbsp;/gi, ' ')
                        .replaceAll(/&ndash;/gi, '–')
                        .replaceAll(/&tm;/gi, '™')
                        .replaceAll(/&reg;/gi, '®'),
                    ...components,
                }}
                components={{
                    ...commonComponent,
                    ...components,
                }}
            />
        ),
    };
};
